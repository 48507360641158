import axios from "axios";

// Smooth anchor link scroll
document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
  anchor.addEventListener("click", function (e) {
    e.preventDefault();

    document.querySelector(this.getAttribute("href")).scrollIntoView({
      behavior: "smooth",
    });
  });
});

// Init glider.js
window.addEventListener("load", function () {
  new Glider(document.querySelector("#our-team .glider"), {
    arrows: {
      prev: "#our-team .glider-prev",
      next: "#our-team .glider-next",
    },
    dots: "#our-team .dots",
    rewind: true,
    scrollLock: true,
  });
  document
    .querySelector("#testimony .glider")
    .addEventListener("glider-slide-visible", function (event) {
      var subtitleElList = document.querySelectorAll(".testimony-subtitle");
      subtitleElList.forEach(function (el) {
        el.classList.remove("active");
      });
      var slide = event.detail.slide;
      if (slide >= 0 && slide <= 2) {
        subtitleElList[0].classList.add("active");
      } else if (slide >= 3 && slide <= 5) {
        subtitleElList[1].classList.add("active");
      } else if (slide >= 6 && slide <= 8) {
        subtitleElList[2].classList.add("active");
      }
    });
  new Glider(document.querySelector("#testimony .glider"), {
    arrows: {
      prev: "#testimony .glider-prev",
      next: "#testimony .glider-next",
    },
    dots: "#testimony .dots",
    rewind: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    scrollLock: true,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  });
});

// Modal video
$(".home-video-container").modalVideo();

// Modal
var courseModal = document.getElementById("course-modal");
var offerModal = document.getElementById("offer-modal");
var imageModal = document.getElementById("image-modal");

var courseModalClose = document.querySelector("#course-modal .modal-close");
var offerModalClose = document.querySelector("#offer-modal .modal-close");
var imageModalClose = document.querySelector("#image-modal .modal-close");

window.openCourseModalDialog = function (course) {
  var courseInput = document.getElementById("course");
  courseInput.value = course;
  courseModal.style.display = "flex";
};

window.openOfferModalDialog = function () {
  offerModal.style.display = "flex";
  imageModal.style.display = "none";
};

courseModalClose.onclick = function () {
  courseModal.style.display = "none";
};

offerModalClose.onclick = function () {
  offerModal.style.display = "none";
};

imageModalClose.onclick = function () {
  imageModal.style.display = "none";
};

window.addEventListener("mousedown", function (event) {
  if (event.target == courseModal) {
    courseModal.style.display = "none";
  }
  if (event.target == offerModal) {
    offerModal.style.display = "none";
  }
  if (event.target == imageModal) {
    imageModal.style.display = "none";
  }
});

// Send e-mail
var toast = new Toasty();

window.sendEbookEmail = function (event) {
  event.preventDefault();
  var { target } = event;
  var data = {
    email: target.email.value,
  };

  var submitButton = target.querySelector("[type=submit]");
  submitButton.disabled = true;
  axios
    .post("/api/mail/ebook", data)
    .then(function () {
      toast.success("Um e-mail foi enviado a sua caixa de entrada.");
    })
    .catch(function () {
      toast.error("Ocorreu um erro ao enviar o e-mail");
    })
    .finally(() => {
      submitButton.disabled = false;
    });
  return false;
};

window.sendCoursesEmail = function (event) {
  event.preventDefault();
  var { target } = event;
  var data = {
    name: target.name.value,
    email: target.email.value,
    phone: target.phone.value,
    course: target.course.value,
  };
  var submitButton = target.querySelector("[type=submit]");
  submitButton.disabled = true;
  axios
    .post("/api/mail/course", data)
    .then(function () {
      toast.success("Um e-mail foi enviado a sua caixa de entrada.");
    })
    .catch(function () {
      toast.error("Ocorreu um erro ao enviar o e-mail");
    })
    .finally(() => {
      submitButton.disabled = false;
    });
  return false;
};

window.sendOfferEmail = function (event) {
  event.preventDefault();
  var { target } = event;
  var data = {
    name: target.name.value,
    email: target.email.value,
    phone: target.phone.value,
  };
  var submitButton = target.querySelector("[type=submit]");
  submitButton.disabled = true;
  axios
    .post("/api/mail/offer", data)
    .then(function () {
      toast.success("Informações enviados com sucesso.");
    })
    .catch(function () {
      toast.error("Ocorreu um erro ao enviar as informações.");
    })
    .finally(() => {
      submitButton.disabled = false;
    });
  return false;
};
